
import * as Components from "./Unclereds"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "unclereds"
}

